import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Card from '../components/card/Card';
import Layout from '../components/layout/Layout';

const Hero = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  padding: 150px 40px;
`;

const Titles = styled.div`
  max-width: 500px;
  margin-right: 40px;
`;

const Title = styled.h1`
  animation: fade-content 0.85s 2.1s cubic-bezier(0.2, 1, 0.2, 1) forwards;
  font-family: 'Source Sans Pro';
  font-size: 70px;
  font-weight: 700;
  margin: 0 0 20px;
  opacity: 0;
  transform: perspective(800px) rotateX(-60deg) translateY(50px);
`;

const Subtitle = styled.h2`
  animation: fade-content 0.7s 2.2s forwards;
  color: #6c757d;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: 400;
  opacity: 0;
  transform: perspective(800px) rotateX(-50deg) translateY(30px);
`;

const Listing = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  padding: 40px;

  @media (min-width: 768px) {
    &::after {
      content: '';
      flex: 0 0 47%;
    }
  }

  @media (min-width: 1224px) {
    &::after {
      content: '';
      flex: 0 0 30%;
    }
  }
`;

const Loading = styled.div`
  animation: fade-content 0.7s 0.1s forwards;
  display: flex;
  color: #6c757d;
  flex-wrap: wrap;
  font-family: 'Source Sans Pro';
  font-size: 40px;
  font-weight: 400;
  justify-content: center;
  margin: 0 auto;
  max-width: 1280px;
  opacity: 0;
  padding: 40px;
  text-align: center;
  transform: perspective(800px) rotateX(-50deg) translateY(30px);
`;

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const Clearbit = styled.a`
  color: #6c757d;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default ({ pageContext, data }) => {
  const [filteredMakers, setFilteredMakers] = useState(data?.items?.nodes);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setFilteredMakers(data?.items?.nodes);
    setLoading(false);
  }, [data, loading]);

  return (
    <Layout>
      <Hero>
        <Titles>
          <Title>Open Makers</Title>
          <Subtitle>A curated list of makers who opened their metrics</Subtitle>
        </Titles>
      </Hero>

      {loading ? (
        <Loading>
          We are currently looking for makers who have opened their metrics...
        </Loading>
      ) : (
        <Listing>
          {filteredMakers?.map(({ data }) => (
            <Card key={data?.slug} {...data} />
          ))}
        </Listing>
      )}

      <Copyright>
        <Clearbit href="https://clearbit.com" target="_blank">
          Logos provided by Clearbit
        </Clearbit>
      </Copyright>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery($tableName: String!) {
    items: allAirtable(filter: { table: { eq: $tableName } }) {
      nodes {
        data {
          slug
          name
          description
          clearbit
          url
        }
      }
    }
  }
`;
