import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

const Wrapper = styled.div``;

export default function Layout({ children }) {
  return (
    <Wrapper>
      <Global
        styles={css`
          body {
            background-color: #ffffff;
            color: #212529;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
              Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
            overflow-x: hidden;

            &::before {
              background: #fafafa;
              content: '';
              display: block;
              height: 50vh;
              position: absolute;
              transform: skewY(-15deg);
              transform-origin: 0;
              width: 100vw;
              z-index: -1;
            }
          }

          * {
            box-sizing: border-box;
          }

          img {
            vertical-align: top;
          }

          @keyframes fade-content {
            to {
              opacity: 1;
              transform: none;
            }
          }
        `}
      />
      {children}
    </Wrapper>
  );
}
