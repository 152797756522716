import styled from '@emotion/styled';
import React from 'react';

function template(i, duration) {
  let animationDelay = duration * (i + 3);

  return `
    &:nth-of-type(${i + 1}) {
      animation: fade-content .85s ${animationDelay}0ms cubic-bezier(.2, 1, .2, 1) forwards;
      }
  `;
}

function getAnimations() {
  let str = '';

  for (let index = 0; index < 20; index += 1) {
    str += template(index, index);
  }

  return str;
}

const CardUI = styled.div`
  ${getAnimations()}

  margin: 0 0 50px;
  min-height: 350px;
  opacity: 0;
  transform: perspective(800px) rotateX(-50deg) translateY(30px);
  width: 100%;

  @media (min-width: 768px) {
    width: 47%;
  }

  @media (min-width: 1224px) {
    width: 30%;
  }
`;

const Link = styled.a`
  align-items: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 30px hsla(0, 0%, 75.3%, 0.555);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 60px;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease-out;

  &:hover {
    transform: translateY(-4px);

    span {
      opacity: 1;
      transition: opacity 0.25s linear;
      visibility: visible;
    }
  }
`;

const Image = styled.img`
  border-radius: 4px;
  max-height: 45px;
`;

const Title = styled.h2`
  color: #343a40;
  font-weight: 700;
`;

const Description = styled.p`
  color: #6c757d;
  font-weight: 300;
`;

const Span = styled.span`
  color: #6c757d;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s linear;
  visibility: hidden;
`;

const Card = ({ description, clearbit, name, url }) => {
  return (
    <CardUI>
      <Link href={url} target="_blank" rel="noopener noreferrer">
        <Image
          alt={name}
          src={clearbit}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = `https://www.openmakers.co/favicon.png`;
          }}
        />
        <Title>{name}</Title>
        <Description>{`${description?.substring(0, 50)} ...`}</Description>
        <Span>→ Visit Open Page</Span>
      </Link>
    </CardUI>
  );
};

export default Card;
